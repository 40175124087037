/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices } from '../../actions/devices';
import EditDeviceModal from '../Admin/EditDevice/modal'
import { DataTable } from '../Utils/reusableTable';
import { PropagateLoader } from 'react-spinners';


function ViewEditDevices() {
  const devices = useSelector((state) => state.devices.devices);
  const devicesCount = useSelector((state) => state.devices.devicesCount);

  const buttonColumns = ['Logs', 'Events', 'Load History']

  const dispatch = useDispatch();

  const globalState = useSelector((state) => state);
  const user = globalState.user.user;
  const accessLevel = user.AccessLevel;

  const [show, setShow] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(false);
  
  const [ready, setReady] = useState(false)

  const handleEditDevice = (device) => {
    setShow(true)
    setCurrentDevice(device)
  }

  const handleDeviceUpdated =  () => {
    setShow(false)
    dispatch(getDevices());
  }

  useEffect(() => {
    dispatch(getDevices());
  }, [dispatch, show]);

  useEffect(() => {
    if (devicesCount != undefined) {
      setReady(true);
    }
  }, [devicesCount])

  const buttonsData = {
    "Logs": {
      name: "Logs",
      routePath: "/devices-data-log?id=",
      paramsKey: "IMEINumber",
      variant: "warning"
    },
    "Events": {
      name: "Events",
      routePath: "/device-events?id=",
      paramsKey: 'IMEINumber',
      variant: "info"
    },

    "Load History": {
      name: " Load ",
      routePath: "/device-load-history?id=",
      paramsKey: 'IMEINumber',
      variant: "primary"
    }
  }

  const displayColumns = [
    "Real_Name" ,"FCIUniqueSerialNumber", "model" , "BatteryMCUStatus", "BatteryLEDStatus",
    "LastCheckInTime", "Lat", "Long",  "gps_lat", "gps_long"
  ]
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  return (
    <div>
      {!ready ? (
        <PropagateLoader className="Center mt-4" size={10} color="#000" speedMultiplier={0.7} />
      ) : (
        <>
          <DataTable
            displayColumns={displayColumns}
            tableData={devices}
            totalCount={devicesCount}
            buttonColumns={buttonColumns}
            buttonsData={buttonsData}
            enableDownloadCSV={true}
            showFilter={false}
            csvFileName={'Devices.csv'}
            usePaper={false}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            paginationMode="client"
          />

          <EditDeviceModal
            accessLevel={accessLevel}
            show={show}
            setShow={setShow}
            device={currentDevice}
            handleDeviceUpdated={handleDeviceUpdated}
          />
        </>
      )}
    </div>
  );
}


export {
  ViewEditDevices
}
