/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatalogById } from '../../actions/devices';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { convertColumnToRealName } from '../../lib/convertColumnsToRealLanguage';
import { DataTable } from '../Utils/reusableTable';
import { PropagateLoader } from 'react-spinners';

export default function ViewDevicesDataLogById(props) {
  const devicesDataLogArr = useSelector((state) => state.devices.datalogById);
  const [columns, setColumns] = useState();
  const [data, setData] = useState();
  const [imeiNumber, setImeiNumber] = useState()
  const [ready, setReady] = useState(undefined)
  const dispatch = useDispatch();
  
  const displayColumns = [
    "Real_Name" ,"FCIUniqueSerialNumber", "date",  "Ongoing_fault", "PreviousAverageCurrent1m",
    "PreviousAverageCurrent1h","PreviousPeakCurrent24h","BatteryMCUStatus", "BatteryLEDStatus",
    "LastCheckInTime", "Lat", "Long",  "gps_long", "gps_lat","IndexID" ,"RecordID", "DM1_Event_ID", 
    "DM1_EventType", "DM1_PhaseAEvent", "DM1_PhaseBEvent","DM1_PhaseCEvent", "DM1_PacketTimestamp", 
    "DM1_PhaseAEventStatus", "DM1_PhaseBEventStatus", "DM1_PhaseCEventStatus", "DM1_Event_Record_ID"
  ]
  const overrideNotToDisplay = [
    "DM1_Event_ID", "DM1_EventType", "DM1_PhaseAEvent", "DM1_PhaseBEvent","DM1_PhaseCEvent", "DM1_PacketTimestamp",
    "DM1_PhaseAEventStatus", "DM1_PhaseBEventStatus", "DM1_PhaseCEventStatus", "DM1_Event_Record_ID"
  ]


  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalCount, setTotalCount] = useState(undefined)
  
  useEffect(() => {
    setReady(false)
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    setImeiNumber(id)
    dispatch(getDatalogById(id));
  }, [dispatch, imeiNumber]);
  
  useEffect(() => {
    if (devicesDataLogArr?.length) {
      const tempColumns = [];
      const objectKeys = Object.keys(devicesDataLogArr[0]);
      objectKeys &&
        objectKeys.map((key) => {
          if (key != 'GeoCoords'){
            tempColumns.push({
              dataField: convertColumnToRealName(key),
              text: convertColumnToRealName(key),
            });
          }
        });
      const tempData = [];
      devicesDataLogArr.map((data) => {
        const tempObj = {};
        objectKeys &&
          objectKeys.map((key) => {
            tempObj[convertColumnToRealName(key)] = data[key];
          });
        tempData.push(tempObj);
      });
      setColumns(tempColumns);
      setData(tempData);
      setTotalCount(devicesDataLogArr.length)
    } else {
      setData([]);
      setTotalCount(0)
    }
    setReady(true)
  }, [devicesDataLogArr]);

  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }

    return !ready
  }

  return (
    <>
      {showLoader([devicesDataLogArr]) ? (
        <PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />
      ) : (
        <div style={{ overflowX: 'auto' }}>
          {
            <ToolkitProvider keyField="id" data={data} columns={columns} exportCSV>
              {(props) => (
                <div>
                  <DataTable
                    displayColumns={displayColumns}
                    tableData={devicesDataLogArr}
                    totalCount={totalCount}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    enableDownloadCSV={true}
                    showFilter={false}
                    csvFileName={`Device #${imeiNumber} logs.csv`}
                    usePaper={true}
                    paginationMode="client"
                    overrideNotToDisplay={overrideNotToDisplay}
                  />
                </div>
              )}
            </ToolkitProvider>
          }
        </div>
      )}
    </>
  );
}
