/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminGroups, getAdminOrganizations, getAdminUsers } from '../../../actions/adminActions';
import EditGroupModal from './modal';
import { toggleAdminSubMenu } from '../../../actions/ui';
import { deleteGroupApiCall } from '../../../services/users';
import { DeleteModal } from '../../Modal/DeleteModal';

export default function EditGroups() {
  const adminGroups = [...(useSelector((state) => state.admin.groups) || [])];
  const adminOrganizations = [...(useSelector((state) => state.admin.organizations) || [])];
  const showAdminSubMenu = useSelector((state) => state.ui.showAdminSubMenu);
  const [show, setShow] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteFailure, setDeleteFailure] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [currentGroup, setCurrentGroup] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (group) => {
    setShow(true);
    setCurrentGroup(group);
  };
  const handleShowDelete = (group) => {
    setShowDelete(true);
    setCurrentGroup(group);
  };
  const onCancelDeleteBtn = () => {
    setShowDelete(false);
    setDeleteSuccess(false);
    setDeleteFailure(false);
  };
  const onAcceptDeleteBtn = async (groupId) => {
    try {
      await deleteGroupApiCall(groupId);
      setDeleteSuccess(true);
      setShowDelete(false);
      dispatch(getAdminUsers());
      dispatch(getAdminGroups());
      dispatch(getAdminOrganizations());
    } catch (error) {
      setDeleteFailure(true);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminUsers());
    dispatch(getAdminGroups());
    dispatch(getAdminOrganizations());
    if (!showAdminSubMenu) {
      dispatch(toggleAdminSubMenu());
    }
  }, [dispatch]);
  return (
    <div>
      <Table responsive="md">
        <thead>
          <tr>
            <th>GroupID</th>
            <th>GroupName</th>
            <th>Organization</th>
            <th>Users</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {adminGroups?.map((group) => {
            let adminOrg = adminOrganizations.find(
              (org) => org.OrganizationID == group.OrganizationID,
            );
            let adminName = '';
            if (adminOrg) {
              adminName = adminOrg.Name;
            }
            return (
              <tr key={group.GroupID}>
                <td>{group.GroupID}</td>
                <td>{group.GroupName}</td>
                <td>{adminName}</td>
                <td>
                  {group.users
                    .map((user) => user.UserNameLong)
                    .slice(0, 2)
                    .join(', ')}
                  <small className="text-muted">
                    {group.users.length > 2 && ` ...and ${group.users.length - 2} more`}
                  </small>
                </td>
                <td>
                  <Button variant="primary" onClick={() => handleShow(group)}>
                    Edit Group
                  </Button>
                </td>
                <td>
                  <Button variant="danger" onClick={() => handleShowDelete(group)}>
                    Delete Group
                  </Button>
                </td>
              </tr>
            );})}
        </tbody>
      </Table>
      <EditGroupModal
        show={show}
        handleShow={handleShow}
        group={currentGroup}
        handleClose={handleClose}
      />
      <DeleteModal
        show={showDelete}
        name="Group"
        deleteSuccess={deleteSuccess}
        onClickNo={onCancelDeleteBtn}
        onClickYes={onAcceptDeleteBtn}
        deleteFailure={deleteFailure}
        id={currentGroup?.GroupID}
      />
    </div>
  );
}
