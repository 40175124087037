/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

import {
  updateOrganizationsApiCall,
  addOrganizationApiCall,
} from '../../../services/organizations';

export function EditOrganizationModal(props) {
  const [organizationID, setOrganizationID] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationDomain, setOrganizationDomain] = useState('');
  const [organizationDnp3SqsTopic, setOrganizationDnp3SqsTopic] = useState('');
  const [defaultGroups, setDefaultGroups] = useState([]);

  useEffect(() => {
    if (props.Organization && props.Organization.OrganizationID) {
      setOrganizationID(props.Organization.OrganizationID);
      setOrganizationName(props.Organization.Name);
      setOrganizationDomain(props.Organization.Domain);
      setOrganizationDnp3SqsTopic(props.Organization.Dnp3SqsTopicURL);
      setDefaultGroups(
        props.Organization.DefaultGroups.map((group) => {
          const adminGroup = props.adminGroups.find((gp) => gp.GroupName == group);
          return {
            value: adminGroup.GroupID,
            label: adminGroup.GroupName,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Organization]);

  function isValidUrl(url) {
    const urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    return urlRegex.test(url);
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.setLoading(true);

    if (!isValidUrl(organizationDomain)) {
      toast.warn('Please enter a valid Domain');
      props.setLoading(false);
      return;
    }

    const OrganizationData = {
      Name: organizationName,
      Domain: organizationDomain,
      Dnp3SqsTopicURL: organizationDnp3SqsTopic,
      DefaultGroups: defaultGroups.map((option) => option.value).join(),
    };

    updateOrganizationsApiCall(OrganizationData, organizationID)
      .then((res) => {
        if (res.data.success) {
          toast.success('Organization Modified');
        } else {
          toast.error('Error occured');
        }
        props.setLoading(false);
        props.handleOrganizationUpdated();
      })
      .catch((err) => {
        props.setLoading(false);
        toast.error('Error occured');
      });
  }
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control
                className="mb-3"
                autoFocus
                type="text"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="group">
              <Form.Label>Organization Domain</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                value={organizationDomain}
                onChange={(e) => setOrganizationDomain(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="group">
              <Form.Label>DNP3 SQS Topic URL</Form.Label>
              <Form.Control
                className="mb-3"
                type="text"
                value={organizationDnp3SqsTopic}
                onChange={(e) => setOrganizationDnp3SqsTopic(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="OrganizationForm.defaultGroups">
              <Form.Label>Group Associations</Form.Label>
              <Select
                className="mb-3"
                multiple
                onChange={(selectedOptions) => {
                  if (selectedOptions === null) {
                    setDefaultGroups([]);
                  } else {
                    setDefaultGroups(selectedOptions);
                  }
                }}
                options={props.adminGroups.map((adminGroup) => ({
                  value: adminGroup.GroupID,
                  label: adminGroup.GroupName,
                }))}
                isMulti={true}
                value={defaultGroups}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            size="small"
            variant="outlined"
            onClick={props.handleClose}
            className="float-left"
            disabled={props.loading}
          >
            Close
          </Button>
          <Button
            size="small"
            variant="contained"
            className="float-right"
            type="submit"
            disabled={!organizationName.length || props.loading}
            onClick={handleSubmit}
          >
            {props.loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
            {!props.loading && 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function NewOrganizationModal(props) {
  const defaultGroupsSelectRef = useRef();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationDomain, setOrganizationDomain] = useState('');
  const [organizationDnp3SqsTopic, setOrganizationDnp3SqsTopic] = useState('');
  const [defaultGroups, setDefaultGroups] = useState([]);

  function clearForm(event) {
    setOrganizationName('');
    setOrganizationDomain('');
    setOrganizationDnp3SqsTopic('');
    setDefaultGroups([]);
    event.target.reset();

    defaultGroupsSelectRef.current.clearValue();
  }

  function isValidUrl(url) {
    const urlRegex = /^(?:(?:https?):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    return urlRegex.test(url);
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.setLoading(true);

    if (!isValidUrl(organizationDomain)) {
      toast.warn('Please enter a valid domain');
      props.setLoading(false);
      return;
    }

    const msOrganizationData = {
      Name: organizationName,
      Domain: organizationDomain,
      Dnp3SqsTopicURL: organizationDnp3SqsTopic,
      DefaultGroups: defaultGroups.join(),
    };

    addOrganizationApiCall(msOrganizationData)
      .then((res) => {
        if (res.data.success) {
          toast.success('Organization Added');
        } else {
          toast.error('Error occured');
        }
        props.setLoading(false);
        props.handleOrganizationAdded();
        clearForm(event);
      })
      .catch((err) => {
        props.setLoading(false);
        toast.error('Error occured');
      });
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="group">
            <Form.Label>Organization Name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="group">
            <Form.Label>Organization Domain</Form.Label>
            <Form.Control type="text" onChange={(e) => setOrganizationDomain(e.target.value)} />
          </Form.Group>
          <Form.Group size="lg" controlId="group">
            <Form.Label>DNP3 SQS Topic URL</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setOrganizationDnp3SqsTopic(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="msOrganizationForm.defaultGroups">
            <Form.Label>Default Group Associations</Form.Label>
            <Select
              multiple
              ref={defaultGroupsSelectRef}
              onChange={(selectedOptions) => {
                if (selectedOptions === null) {
                  setDefaultGroups([]);
                } else {
                  setDefaultGroups(selectedOptions.map((option) => option.value));
                }
              }}
              options={props.adminGroups.map((adminGroup) => ({
                value: adminGroup.GroupID,
                label: adminGroup.GroupName,
              }))}
              isMulti={true}
            />
          </Form.Group>

          <Button
            size="small"
            variant="contained"
            className="mt-4 float-right"
            type="submit"
            disabled={!organizationName.length || props.loading}
          >
            {props.loading && <BeatLoader size={10} color="#fff" speedMultiplier={0.7} />}
            {!props.loading && 'Create'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
