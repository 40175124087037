import { SET_ADMIN_GROUPS, SET_ADMIN_USERS, SET_ADMIN_DEVICES, SET_ADMIN_ORGANIZATIONS } from '../constants/action-types';
import {
  getAdminDevicesApiCall,
  getAdminGroupsApiCall,
  getAdminOrganizationsApiCall,
  getAdminUsersApiCall,
} from '../services/users';

export const getAdminUsers = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getAdminUsersApiCall();
      dispatch({ type: SET_ADMIN_USERS, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};
export const getAdminDevices = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getAdminDevicesApiCall();
      dispatch({ type: SET_ADMIN_DEVICES, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};
export const getAdminGroups = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getAdminGroupsApiCall();
      dispatch({ type: SET_ADMIN_GROUPS, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};
export const getAdminOrganizations = () => {
  return async (dispatch) => {
    try {
      const apiRes = await getAdminOrganizationsApiCall();
      dispatch({ type: SET_ADMIN_ORGANIZATIONS, payload: apiRes.data });
    } catch (error) {
      // handle when auth failed.
    }
  };
};
