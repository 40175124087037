/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDeviceLoadHistoryByIdApiCall } from '../../services/devices';
import { DataTable } from '../Utils/reusableTable';
import { getDeviceLoadHistory, getDevices } from '../../actions/devices';
import { getTitleAsCurrentNameSerialNumber } from '../Utils/helpers'
import { PropagateLoader } from 'react-spinners';

export default function ViewDeviceLoadHistoryById(props) {
  const { loadHistory, totalCount: loadhistoryCount } = useSelector((state) => state.devices.loadhistory);
  const devices = useSelector((state) => state.devices.devices) || [];

  const [imeiNumber, setImeiNumber] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [downloading, setDownloading] = useState(false);
  const [ready, setReady] = useState(undefined)
  const [pageCount, setPageCount] = useState(undefined)
  const [columns, setColumns] = useState(undefined)

  const overrideNotToDisplay = ['IMEINumber']

  function showLoader(requiredData=[]){
    for (let index = 0; index < requiredData.length; index++) {
      if(requiredData[index] === undefined){
        return true
      }
    }
    return !ready
  }

  const dispatch = useDispatch()
  
  useEffect(() => {
    setReady(false)
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    setImeiNumber(id);
    dispatch(getDeviceLoadHistory(id, page, pageSize))
    dispatch(getDevices())
    getColumns()
  }, [dispatch, imeiNumber, page, pageSize, loadhistoryCount]);

  useEffect(() => {
    setReady(true)
    setPageCount(loadHistory.length)
  }, [columns, loadHistory])

  function getColumns(){
    if(loadHistory.length > 0){
      const loadHistoryCols = Object.keys(loadHistory[0])
      setColumns(loadHistoryCols)
    }else{
      setColumns([])
    }
  }

  const onDownloadClick = useCallback(
    async (from, to) => {
      setDownloading(true);
      const res = await getDeviceLoadHistoryByIdApiCall(imeiNumber, 0, loadhistoryCount, from, to);
      setDownloading(false);
      if (res.data[1].length) {
        return res.data[1];
      }
      return [{ Result: 'No data available for the selected date range' }];
    },
    [loadhistoryCount, imeiNumber],
  );

  return (
    <div>
      {
        showLoader([loadHistory, pageCount, columns]) ? (<PropagateLoader className="Center" size={10} color="#000" speedMultiplier={0.7} />) :
        (

          <DataTable
            title={getTitleAsCurrentNameSerialNumber(devices, imeiNumber)}
            displayColumns={columns}
            tableData={loadHistory}
            totalCount={loadhistoryCount}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            enableDownloadCSV={true}
            csvFileName={`Device #${imeiNumber} load history.csv`}
            usePaper={true}
            paginationMode='server'
            handleDownload={onDownloadClick}
            downloadProgress={downloading}
            overrideNotToDisplay={overrideNotToDisplay}
          />
        )
      }
    </div>
  );
}
